const GradientContainer = ({
  style,
  children,
  className,
  gradient = {
    topLeft: '#4c67f6',
    topRight: '#82cfe5',
    bottomLeft: '#5263f1',
    bottomRight: '#fe8b60',
    center: '#957ec6',
  },
}: {
  style?: object;
  children?: any;
  className?: string;
  gradient?: {
    topLeft: string;
    topRight: string;
    bottomLeft: string;
    bottomRight: string;
    center: string;
  };
}) => {
  const gradientStyle = {
    background: `linear-gradient(
        to top left,
        ${gradient.bottomRight},
        rgba(255, 153, 150, 0),
        ${gradient.topLeft}
      ),
      linear-gradient(to top right, ${gradient.bottomLeft}, rgba(255, 153, 150, 0), ${gradient.topRight})
        ${gradient.center}`,
    ...style,
  };
  return (
    <div style={gradientStyle} className={className}>
      {children}
    </div>
  );
};

export default GradientContainer;
